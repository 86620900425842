import TooltipType from 'models/enums/TooltipType.enum';
import TooltipPositoinType from 'models/enums/TooltipPositoinType.enum';
import appService from 'store/appService';
import userService from 'store/userService';
import roomServices from 'store/roomService';
import controlPanelServices from 'store/controlPanelService';
import modalServices from 'store/modalService';
import tooltipsServices from 'store/tooltipsService';
import useL10n from 'l10n/useL10n';
import useAppData from 'hooks/useAppData';
import {FunctionComponent, useEffect, useState, useRef} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import Button from 'components/hoc/Button';
import Tooltip from 'components/tooltip/Tooltip';
import useAnalytics from 'hooks/useAnalytics';
import useUser from 'hooks/useUser';
import {CopybettingType} from 'models/enums/CopybettingType.enum';
import MessageType from 'models/enums/MessageType.enum';

import threadService from 'store/threadService';
import copybettingService from 'store/copybettingService';

import ControlPanelAttach from './ControlPanelAttach';

const TRANSITION_DURATION_BUTTONS = 500;

const ControlPanelLeft: FunctionComponent = function ControlPanelLeft() {
	const referenceWaitingSpeakers = useRef<HTMLDivElement>(null);
	const referenceShareBet = useRef<HTMLDivElement>(null);
	const inputTypeFileRef = useRef<HTMLInputElement | null>(null);

	const {
		appReadOnly,
		appVoice,
		appIcons,
		appBettingButtonVisible,
		copybettingType,
		appEnableGamble,
		enableMessagePics,
	} = useLocalObservable(() => appService);
	const {userData, isUserExternalIdCorrect} = useLocalObservable(() => userService);
	const {currentThreadId} = useLocalObservable(() => threadService);
	const {talkersWaitingSpeakers, myTalker} = useLocalObservable(() =>
		currentThreadId ? threadService : roomServices
	);
	const {visibleButtons, attachSubmenuVisible, toggleAttachSubmenuVisible} = useLocalObservable(
		() => controlPanelServices
	);
	const {
		toggleWaitingSpeakersModal,
		toggleShareBetModal,
		toggleShareGambleModalVisible,
		imagePreviewModalVisible,
	} = useLocalObservable(() => modalServices);
	const {tooltip, toggleTooltip} = useLocalObservable(() => tooltipsServices);
	const {currentBetForShare} = useLocalObservable(() => copybettingService);

	const [visibleTooltip, setVisibleTooltip] = useState(false);
	const [visibleShareBetTooltip, setVisibleShareBetTooltip] = useState(false);

	const {sendAnalytics} = useAnalytics();

	const {tooltips} = useL10n();
	const {getAppIcon} = useAppData();
	const {userExtraDataCheckTooltip, userExtraDataPatchTooltip} = useUser();

	const {IcoHand, IcoHandAccent, IcoAttach} = appIcons;

	const myTalkerIsModer = !!myTalker?.isModer || !!userData?.isModer;
	const myTalkerIsBanned = !!myTalker?.bans?.length;
	const haveTalkersWaitingSpeakers = talkersWaitingSpeakers.length > 0;

	const appBettingVisible = appBettingButtonVisible && !myTalkerIsBanned && !myTalkerIsModer;
	const appGambleVisible = appEnableGamble && !myTalkerIsBanned && !myTalkerIsModer;
	const appWaitingSpeakersVisible = myTalkerIsModer && appVoice;

	const onToggleWaitingSpeakersHandler = () => {
		if (isUserExternalIdCorrect) {
			toggleWaitingSpeakersModal(true);
		}
	};

	const openShareGambleModal = () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			toggleShareGambleModalVisible(true);
		}
	};

	const openShareBetModal = () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			if (copybettingType === CopybettingType.BACK_TO_BACK) toggleShareBetModal(true);
			if (copybettingType === CopybettingType.FRONT_TO_FRONT) {
				const msg = {
					type: 'betRepeat',
					body: {action: 'showList', data: {}},
				};

				if (typeof WatchersChannel !== 'undefined') {
					WatchersChannel.postMessage(JSON.stringify(msg));
				} else window.parent.postMessage(msg, '*');
			}
			toggleAttachSubmenuVisible(false);
			sendAnalytics('betshare_list_opened');
		}
	};

	const openShareModal = (type: MessageType) => {
		if (type === MessageType.GAMBLE) {
			openShareGambleModal();
			return;
		}
		openShareBetModal();
	};

	const onAttachClickHandler = () => {
		if (
			((appBettingVisible || appGambleVisible) && enableMessagePics) ||
			(appBettingVisible && appGambleVisible)
		) {
			toggleAttachSubmenuVisible(!attachSubmenuVisible);
			return;
		}
		if (appBettingVisible) {
			openShareBetModal();
			return;
		}

		if (appGambleVisible) {
			openShareGambleModal();
			return;
		}

		if (enableMessagePics) {
			inputTypeFileRef.current?.click();
		}
	};

	useEffect(() => {
		if (
			myTalkerIsModer &&
			haveTalkersWaitingSpeakers &&
			(!tooltip ||
				!tooltip.shown ||
				(tooltip && tooltip.shown && tooltip.type === TooltipType.INFORMATIONAL))
		) {
			if (userData && !userExtraDataCheckTooltip(userData, 'waitingSpeakers')) {
				setVisibleTooltip(true);
				if (!tooltip?.shown) {
					toggleTooltip({shown: true, type: TooltipType.TRIGGER});
				}
				userExtraDataPatchTooltip(userData, 'waitingSpeakers');
			}
		}
	}, [haveTalkersWaitingSpeakers, tooltip, userData]);

	useEffect(() => {
		if (
			!myTalkerIsModer &&
			(!tooltip ||
				!tooltip.shown ||
				(tooltip && tooltip.shown && tooltip.type === TooltipType.INFORMATIONAL))
		) {
			if (userData && !userExtraDataCheckTooltip(userData, 'shareBet')) {
				setVisibleShareBetTooltip(true);
				if (!tooltip?.shown) {
					toggleTooltip({shown: true, type: TooltipType.TRIGGER});
				}
				userExtraDataPatchTooltip(userData, 'shareBet');
			}
		}
	}, [tooltip]);

	if (imagePreviewModalVisible) return null;
	return (
		<CSSTransition
			in={
				visibleButtons &&
				(appBettingVisible || appGambleVisible || appWaitingSpeakersVisible || enableMessagePics)
			}
			timeout={TRANSITION_DURATION_BUTTONS}
			classNames='fade'
			unmountOnExit>
			<div className='control-panel__left'>
				{!currentBetForShare && (
					<ControlPanelAttach
						openShareModal={openShareModal}
						appBettingVisible={appBettingVisible}
						appGambleVisible={appGambleVisible}
						inputTypeFileRef={inputTypeFileRef}
					/>
				)}

				{(appBettingVisible || enableMessagePics || appGambleVisible) && !currentBetForShare && (
					<div ref={referenceShareBet} className='control-panel__left-attach'>
						<Button className='control-panel__btn' onClick={onAttachClickHandler}>
							{getAppIcon(IcoAttach.pic)}
						</Button>

						{appBettingVisible && visibleShareBetTooltip && (
							<Tooltip
								referenceElement={referenceShareBet.current}
								position={TooltipPositoinType.TOP}
								text={tooltips.shareBet}
								delay={3000}
								callbackOnHide={() => {
									setVisibleShareBetTooltip(false);
									if (tooltip && tooltip.shown && tooltip.type === TooltipType.TRIGGER) {
										toggleTooltip(null);
									}
								}}
							/>
						)}
					</div>
				)}

				{appWaitingSpeakersVisible && (
					<div ref={referenceWaitingSpeakers} className='control-panel__left-waiting-speakers'>
						<Button className='control-panel__btn' onClick={onToggleWaitingSpeakersHandler}>
							{haveTalkersWaitingSpeakers ? getAppIcon(IcoHandAccent.pic) : getAppIcon(IcoHand.pic)}
						</Button>

						{visibleTooltip && (
							<Tooltip
								referenceElement={referenceWaitingSpeakers.current}
								position={TooltipPositoinType.TOP}
								text={tooltips.waitingSpeakers}
								delay={3000}
								callbackOnHide={() => {
									setVisibleTooltip(false);
									if (tooltip && tooltip.shown && tooltip.type === TooltipType.TRIGGER) {
										toggleTooltip(null);
									}
								}}
							/>
						)}
					</div>
				)}
			</div>
		</CSSTransition>
	);
};

export default observer(ControlPanelLeft);

/* eslint-disable max-lines */
import {TALKER_STUB} from 'constants/constants';
import useL10n from 'l10n/useL10n';
import useSettings from 'hooks/useSettings';
import useAgora from 'hooks/useAgora';
import useAnalytics from 'hooks/useAnalytics';
import {PollStatus} from 'models/enums/Poll.enum';
import UserRole from 'models/enums/UserRole.enum';
import RoomStatus from 'models/enums/RoomStatus.enum';
import MessageNotSentReasonType from 'models/enums/MessageNotSentReasonType';
import {AppEmotion} from 'models/app';
import {Room, Talker, Message, AgoraCreds, Reaction} from 'models/room';
import {Settings, SlowMode} from 'models/settings';
import {Poll, PollOption} from 'models/poll';
import {Offer} from 'models/offer';
import appService from 'store/appService';
import emotionServices from 'store/emotionService';
import roomServices from 'store/roomService';
import toastService from 'store/toastService';
import userServices from 'store/userService';
import pollServices from 'store/pollService';
import modalServices from 'store/modalService';
import controlPanelServices from 'store/controlPanelService';
import stickersEmojiServices from 'store/stickersEmojiService';
import slowModeServices from 'store/slowModeService';
import offerService from 'store/offerService';
import threadService from 'store/threadService';

import {useEffect, useRef} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import {ToastIconsName} from 'components/toasts/ToastIcons';
import {BlockSet, User} from 'models/user';
import {Bet} from 'models/bet';
import useSearchParams from './useSearchParams';
import useTranslation from './useTranslation';
import useChatActions from './useChatActions';

export default () => {
	const translations = useL10n();
	const myTalkerRef = useRef<Talker | null>();
	const roomScrollPositionBottomRef = useRef(false);
	const threadScrollPositionBottomRef = useRef(false);
	const roomPreviousMessagesModeRef = useRef(false);
	const threadPreviousMessagesModeRef = useRef(false);
	const translateModeRef = useRef<any>(null);
	const slowModeRef = useRef<SlowMode>();
	const {userData, updateUserData, translateMode} = useLocalObservable(() => userServices);
	const roomStore = useLocalObservable(() => roomServices);
	const threadStore = useLocalObservable(() => threadService);

	const {
		appVoice,
		setAppVoice,
		removeBlockedUser,
		changeAgoraStatus,
		appNicknameShowed,
		toggleAppNicknameShowed,
		appEnableAgora,
		appEnableMessageTranslation,
		enableRoomSpeak,
		appShowNameToast,
	} = useLocalObservable(() => appService);
	const {addToast} = useLocalObservable(() => toastService);
	const {emotions, addEmotion} = useLocalObservable(() => emotionServices);
	const {setPoll, removePoll, setPollOptionVote, addPollVoteAnim, toggllePollTooltipVisible} =
		useLocalObservable(() => pollServices);
	const {
		togglePollCreateModalVisible,
		togglePollEditModalVisible,
		hideAllPollModals,
		toggleRecordingModal,
		agreementAndChatRulesModal,
	} = useLocalObservable(() => modalServices);
	const {toggleVisibleButtons} = useLocalObservable(() => controlPanelServices);
	const {removeSticker} = useLocalObservable(() => stickersEmojiServices);
	const {slowMode, togglleSlowMode} = useLocalObservable(() => slowModeServices);
	const {setOffers} = useLocalObservable(() => offerService);
	const {userIdFromUrl, nicknameFromUrl, apikeyFromUrl, readonlyFromUrl} = useSearchParams();
	const {getSettingsPartnerWithServices} = useSettings();
	const {
		enableMicrophoneTheSpeaker,
		setClientRole,
		destroyAgora,
		switchMicrophone,
		setMuteClientAudio,
	} = useAgora();
	const {sendAnalytics} = useAnalytics();
	const {translateMessage} = useTranslation();
	const {scrollToMessage} = useChatActions();

	let animationVersion = true;
	let animationVersionPollVote = 1;

	useEffect(() => {
		if (translateMode) {
			translateModeRef.current = translateMode;
		}
	}, [translateMode]);

	useEffect(() => {
		if (roomStore.myTalker) {
			myTalkerRef.current = roomStore.myTalker;
		}
	}, [roomStore.myTalker]);

	useEffect(() => {
		if (roomScrollPositionBottomRef.current !== roomStore.chatScrollPositionBottom) {
			roomScrollPositionBottomRef.current = roomStore.chatScrollPositionBottom;
		}
	}, [roomStore.chatScrollPositionBottom]);

	useEffect(() => {
		if (threadScrollPositionBottomRef.current !== roomStore.chatScrollPositionBottom) {
			threadScrollPositionBottomRef.current = roomStore.chatScrollPositionBottom;
		}
	}, [roomStore.chatScrollPositionBottom]);

	useEffect(() => {
		if (roomPreviousMessagesModeRef.current !== roomStore.previousMessagesMode) {
			roomPreviousMessagesModeRef.current = roomStore.previousMessagesMode;
		}
	}, [roomStore.previousMessagesMode]);

	useEffect(() => {
		if (threadPreviousMessagesModeRef.current !== roomStore.previousMessagesMode) {
			threadPreviousMessagesModeRef.current = roomStore.previousMessagesMode;
		}
	}, [threadStore.previousMessagesMode]);

	useEffect(() => {
		slowModeRef.current = slowMode;
	}, [slowMode]);

	const showUserNameInChat = () => {
		if (
			userIdFromUrl !== '0' &&
			!readonlyFromUrl &&
			nicknameFromUrl &&
			!appNicknameShowed &&
			appShowNameToast
		) {
			toggleAppNicknameShowed(true);
			addToast({
				imgSrc: userData?.pic,
				iconName: ToastIconsName.warning,
				liveTime: 5000,
				message: translations.toasts.yourUserNameInChat(nicknameFromUrl),
				cancellable: true,
			});
		}
	};

	const onSuccessfullyJoined = (data: {room: Room; talker: Talker; ban: {reason: number}}) => {
		const {room, talker, ban} = data;
		if (data.room?.isThread) {
			threadStore.setMyTalker(talker);
			threadStore.setRoomData(room);
			threadStore.setRoomId(room?.externalRoomId);
		}

		if (!room.isThread) {
			roomStore.setMyTalker(talker);
			roomStore.setRoomData(room);

			if (
				(room.isSpeak || enableRoomSpeak) &&
				(room.status === RoomStatus.LIVE || room.status === RoomStatus.SOON)
			) {
				setAppVoice(true);
			}
			if (
				room.status === RoomStatus.ENDED &&
				(room.record || room.audioRecord || room.videoRecord)
			) {
				toggleRecordingModal(true);
			}
			if (!agreementAndChatRulesModal) {
				showUserNameInChat();
			}
		}
		if (ban || talker.bans?.length || talker.user?.bans?.length) {
			toggleVisibleButtons(false);
			return;
		}
		toggleVisibleButtons(true);
	};

	const onSuccessfullyJoinedThread = (data: {thread: Room; talker: Talker}) => {
		threadStore.setCurrentThreadId(data.thread.externalRoomId);
		threadStore.setRoomId(data.thread.externalRoomId);
		threadStore.setMyTalker(data.talker);
		threadStore.setRoomData(data.thread);
	};

	const onGetOffersHandler = (data: {offers: Offer[]; room: Room}) => {
		setOffers(data.offers);
	};

	const onUserJoinedHandler = (data: {room: Room; talker: Talker}) => {
		const {talker} = data;
		if (!data.room.isThread) {
			if (talker?.user.id !== userData?.id) {
				roomStore.addTalker(talker);
				roomStore.increaseTalkersCount();
			}
			if (talker?.role === UserRole.SPEAKER) roomStore.addSpeaker(talker);
		}
	};

	const onUserLeftHandler = (data: any) => {
		if (data.room?.externalRoomId === roomStore.roomId) {
			roomStore.decreaseTalkersCount();
			roomStore.removeTalker(data.id);
			if (myTalkerRef.current && myTalkerRef.current.id === data.id) {
				roomStore.setMyTalker(null);
			}
			if (data.talker?.role === UserRole.SPEAKER) roomStore.removeSpeaker(data.talker.id);
		}
	};

	const onGetMessageHandler = (data: {message: any}) => {
		let localMessage = data.message;

		if (localMessage && !localMessage.talker) {
			localMessage = {...localMessage, talker: TALKER_STUB};
		}

		const localMessageUserId = localMessage.talker.user?.id;

		if (data.message.room.isThread) {
			localMessage.isNotReaded =
				localMessageUserId &&
				threadStore.blockedUsersForFilteredMessages.includes(localMessageUserId)
					? true
					: !threadScrollPositionBottomRef.current;
			if (threadPreviousMessagesModeRef.current) {
				threadStore.addUnrecordedMessage(localMessage);
			} else threadStore.addMessage(localMessage);
		}

		if (!data.message.room.isThread) {
			localMessage.isNotReaded =
				localMessageUserId && roomStore.blockedUsersForFilteredMessages.includes(localMessageUserId)
					? true
					: !roomScrollPositionBottomRef.current;
			if (roomPreviousMessagesModeRef.current) {
				roomStore.addUnrecordedMessage(localMessage);
			} else roomStore.addMessage(localMessage);
		}

		if (localMessage.talker.user?.id !== userData?.id) {
			if (typeof WatchersChannel !== 'undefined') {
				WatchersChannel.postMessage(JSON.stringify({type: 'haveNewMessage'}));
				return;
			}
			if ((window as any).webkit?.messageHandlers) {
				(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(
					JSON.stringify({type: 'haveNewMessage'})
				);
				return;
			}
			window.parent.postMessage({type: 'haveNewMessage'}, '*');
		}
	};

	const onMessageNotSentHandler = (data: {
		reason: MessageNotSentReasonType;
		stickerId?: number;
	}) => {
		const {reason, stickerId} = data;
		if (reason === MessageNotSentReasonType.STICKER_UNAVAILABLE && stickerId) {
			removeSticker(stickerId);
		}
	};

	const onMessageEditedHandler = (data: {message: Message; room?: Room}) => {
		let localMessage = data.message;

		if (localMessage && !localMessage.talker) {
			localMessage = {...localMessage, talker: TALKER_STUB};
		}

		if (
			(localMessage.talker?.user?.id === userData?.id &&
				roomStore.compareMessagePicData(localMessage)) ||
			threadStore.compareMessagePicData(localMessage)
		)
			addToast({
				iconName: ToastIconsName.warning,
				liveTime: 3000,
				message: translations.toasts.imageBlocked,
				onClick: () =>
					scrollToMessage(
						localMessage.id,
						document.querySelector('.chat__messages'),
						document.querySelector('.chat__axis-y'),
						false,
						true
					),
			});

		threadStore.updateMessage(localMessage);
		roomStore.updateMessage(localMessage);

		if (translateModeRef.current.enable && translateModeRef.current.lang.languageCode) {
			translateMessage(translateModeRef.current.lang.languageCode, localMessage);
		}
	};

	const onMessagesDeletedHandler = (data: {isAll: boolean}) => {
		if (data.isAll) {
			roomStore.removeMessages();
		}
	};

	const onMessagesVisibleHandler = (data: {
		userId: number;
		isVisible: boolean;
		hiddenAt: string;
		room?: Room;
	}) => {
		const {userId, isVisible, hiddenAt} = data;
		const talkerIsModer = !!myTalkerRef.current?.isModer;
		if (talkerIsModer || (!talkerIsModer && userData?.id !== userId)) {
			threadStore.updateVisibleMessagesByUserId(userId, isVisible, hiddenAt);
			roomStore.updateVisibleMessagesByUserId(userId, isVisible, hiddenAt);
		}

		if (talkerIsModer) {
			addToast({
				iconName: isVisible ? ToastIconsName.messageShown : ToastIconsName.messageHidden,
				liveTime: 3000,
				message: isVisible ? translations.toasts.messagesShown : translations.toasts.messagesHidden,
				cancellable: true,
			});
		}
	};

	const onMessageVisibleHandler = (data: {message: any}) => {
		const {message} = data;
		const {isVisible, talker} = message;
		const talkerIsModer = !!myTalkerRef.current?.isModer;
		if (talkerIsModer || (!talkerIsModer && userData?.id !== talker.user?.id)) {
			message.room?.isThread
				? threadStore.updateMessage(message)
				: roomStore.updateMessage(message);
			if (isVisible && appEnableMessageTranslation && translateMode.enable)
				translateMessage(translateMode.lang.languageCode, message);
		}

		if (talkerIsModer) {
			addToast({
				iconName: isVisible ? ToastIconsName.messageShown : ToastIconsName.messageHidden,
				liveTime: 3000,
				message: isVisible ? translations.toasts.messageShown : translations.toasts.messageHidden,
				cancellable: true,
			});
		}
	};

	const talkerBanSetHandler = (data: {talker: Talker; ban: {reason: number}}) => {
		const {talker, ban} = data;
		const {bans, user} = talker;
		const talkerIsModer = !!myTalkerRef.current?.isModer || !!myTalkerRef.current?.user?.isModer;
		const isThread = talker.room?.isThread;

		isThread ? threadStore.updateTalker(data.talker) : roomStore.updateTalker(data.talker);

		if (userData?.id === user.id) {
			isThread ? threadStore.updateMyTalker(data.talker) : roomStore.updateMyTalker(data.talker);

			if (bans.length) {
				toggleVisibleButtons(false);
				isThread
					? threadStore.toggleClearChatTextarea(true)
					: roomStore.toggleClearChatTextarea(true);
			} else if (!talker.user?.bans?.length) toggleVisibleButtons(true);
		}
		if (talkerIsModer) {
			addToast({
				iconName: bans.length ? ToastIconsName.userBlocked : ToastIconsName.warning,
				liveTime: 3000,
				message: bans.length ? translations.toasts.userBlocked : translations.toasts.userUnBlocked,
				cancellable: true,
			});
			if (isThread) {
				bans.length
					? threadStore.addBannedTalker(data.talker)
					: threadStore.removeBannedTalker(data.talker);
			} else
				bans.length
					? roomStore.addBannedTalker(data.talker)
					: roomStore.removeBannedTalker(data.talker);
		}
	};

	const onUserBanSetHandler = (data: {
		user: User;
		ban: {reason: number; expires: UTCDateString};
	}) => {
		if (userData?.id === data.user.id) {
			updateUserData({bans: data.user.bans, ban: {...data.ban}});
			roomStore.updateMyTalker({
				...myTalkerRef,
				user: {...myTalkerRef.current?.user, bans: data.user.bans},
			});
			data.user?.bans?.length ? toggleVisibleButtons(false) : toggleVisibleButtons(true);
		}
	};

	const reciveChangeRole = async (data: {talker: any}) => {
		const {talker} = data;
		const talkerIsModer = talker.isModer;
		const realRole =
			talkerIsModer || talker.role === UserRole.SPEAKER ? UserRole.SPEAKER : UserRole.GUEST;

		roomStore.updateTalker(talker);
		roomStore.updateSpeakers(talker);
		roomStore.updateWaitingSpeaker(talker);

		if (appEnableAgora && talker.room.isSpeak && userData?.id === talker.user?.id) {
			await setClientRole(realRole, talker.isMuted, changeAgoraStatus, sendAnalytics);
			roomStore.updateMyTalker(talker);

			if (realRole === UserRole.SPEAKER) {
				enableMicrophoneTheSpeaker();
			}
		}

		if (!talkerIsModer) {
			if (appVoice && appEnableAgora && userData?.id === talker.user?.id) {
				if (
					talker.room &&
					talker.room.status === RoomStatus.SOON &&
					talker.role === UserRole.GUEST
				) {
					destroyAgora(changeAgoraStatus);
				}

				addToast({
					iconName:
						talker.role === UserRole.SPEAKER
							? ToastIconsName.micAccent
							: ToastIconsName.userRemovedSpeakers,
					liveTime: 3000,
					message:
						talker.role === UserRole.SPEAKER
							? translations.toasts.youAreSpeaker
							: translations.toasts.youAreNotSpeaker,
					cancellable: true,
				});
				return;
			}

			const userName = talker.user.name || 'User';
			addToast({
				iconName:
					talker.role === UserRole.SPEAKER
						? ToastIconsName.userAddedSpeakers
						: ToastIconsName.userRemovedSpeakers,
				liveTime: 3000,
				message:
					talker.role === UserRole.SPEAKER
						? translations.toasts.userIsSpeaker(userName)
						: translations.toasts.userNotSpeaker(userName),
				cancellable: true,
			});
		}
	};

	const onChangeModerHandler = (data: {talker: any}) => {
		const {talker} = data;
		const isThread = talker.room?.isThread || talker.room?.externalRoomId.includes('thread_');
		const talkerIsModer = talker.isModer;
		const realRole =
			talkerIsModer || talker.role === UserRole.SPEAKER ? UserRole.SPEAKER : UserRole.GUEST;
		const {youAreModerator, youAreNoModerator} = translations.toasts;
		isThread ? threadStore.updateTalker(talker) : roomStore.updateTalker(talker);

		if (talker.id) {
			!isThread
				? roomStore.updateTalkerMessagesByTalkerId(talker.id, 'isModer', !!talker.isModer)
				: threadStore.updateTalkerMessagesByTalkerId(talker.id, 'isModer', !!talker.isModer);
		}
		if (isThread && talker.id === myTalkerRef.current?.id) threadStore.updateMyTalker(talker);

		if (userData?.id === talker.user?.id && !isThread) {
			appVoice &&
				appEnableAgora &&
				setClientRole(realRole, talker.isMuted, changeAgoraStatus, sendAnalytics);
			roomStore.updateMyTalker(talker);

			addToast({
				iconName: ToastIconsName.userWarning,
				liveTime: 3000,
				message: talker.isModer ? youAreModerator : youAreNoModerator,
				cancellable: true,
			});
		}
	};

	const onHandToggledHandler = (data: {talker: Talker; byModer: boolean}) => {
		const {talker} = data;
		roomStore.updateTalker(talker);
		roomStore.updateWaitingSpeaker(talker);

		if (userData?.id === talker.user?.id) {
			roomStore.updateMyTalker(talker);

			if (data.byModer) {
				addToast({
					iconName: ToastIconsName.handPlainAccent,
					liveTime: 3000,
					message: translations.toasts.requestDecline,
					cancellable: true,
				});
			}

			if (talker.hand) {
				addToast({
					iconName: ToastIconsName.handPlainAccent,
					liveTime: 3000,
					message: translations.toasts.requestSent,
					cancellable: true,
				});
			}
		}
	};

	const reciveAgoraCredsHandler = (data: AgoraCreds) => {
		if (!data.talker?.room?.isThread) roomStore.setAgoraCreds(data);
	};

	const onUserUpdatedHandler = (data: {user: User}) => {
		const {user} = data;
		roomStore.updateUserInfo(user);
		roomStore.updateSpeaker(user);
		if (userData?.id === user.id) {
			updateUserData(user);
		}
	};

	const onEmotionHandler = (data: {emotion: {name: string}}) => {
		const {emotion} = data;
		const findEmotion = emotions.find(emotionLocal => emotionLocal.name === emotion.name);
		if (findEmotion) {
			const generateEmotion: AppEmotion = {
				...findEmotion,
				uuid: uuidv4(),
				versionAnim: animationVersion ? 'first' : 'second',
			};
			animationVersion = !animationVersion;
			addEmotion(generateEmotion);
		}
	};

	const onRoomSpeakSetHandler = (data: {room: Room}) => {
		const {isSpeak} = data.room;
		setAppVoice(isSpeak);
		roomStore.updateRoomData({isSpeak});
	};

	const onRoomStatusSet = (data: {room: Room}) => {
		const {room} = data;
		roomStore.setRoomData(room);
		if (room.status === RoomStatus.ENDED) {
			setAppVoice(false);
			if (room.record || room.audioRecord || room.videoRecord) {
				toggleRecordingModal(true);
			}
			return;
		}
		if (room.status === RoomStatus.DISABLED) {
			setMuteClientAudio(true);
		}
		if (room.status === RoomStatus.LIVE) {
			setMuteClientAudio(false);
		}
		toggleRecordingModal(false);
	};

	const onMuteSetHandler = (data: {
		talker: Talker;
		byModer: boolean;
		initiatorModerUser?: User;
	}) => {
		const {talker, byModer, initiatorModerUser} = data;

		if (talker.user?.id) {
			roomStore.updateTalkerMic(talker.user?.id, talker.isMuted);
		}

		if (userData?.id === talker.user?.id) {
			roomStore.updateMyTalker(talker);

			if (byModer && initiatorModerUser?.id !== talker.user?.id) {
				switchMicrophone(talker.isMuted);

				if (talker.isMuted) {
					addToast({
						iconName: ToastIconsName.mic,
						liveTime: 3000,
						message: translations.toasts.micMuted,
						cancellable: true,
					});
				}
			}
		}
	};

	const onMessagePin = (data: {message: Message}) => {
		const pinnedMessage = data.message;

		roomStore.addPinnedMessage(pinnedMessage);
	};

	const onPollCreatedHandler = (data: {poll: Poll}) => {
		const {poll} = data;
		if (roomStore.roomId && poll) {
			togglePollCreateModalVisible(false);
			setPoll(poll, roomStore.roomId);
			toggllePollTooltipVisible(true);
			if (userData && userData.id === poll.creatorUser.id) {
				togglePollEditModalVisible(true);
			}
		}
	};

	const onPollUpdatedHandler = (data: {poll: Poll}) => {
		const {poll} = data;
		if (roomStore.roomId && poll && poll.status !== PollStatus.PLANNED) {
			togglePollCreateModalVisible(false);
			setPoll(poll, roomStore.roomId);
		}
	};

	const onPollDeletedHandler = (data: {poll: Poll}) => {
		hideAllPollModals();
		roomStore.removeMessageByPollId(data.poll.id);
		removePoll();
	};

	const onPollEndedHandler = () => {
		hideAllPollModals();
		removePoll();
	};

	const onVoteCreatedHandler = (data: {pollOption: PollOption}) => {
		const {pollOption} = data;
		if (data.pollOption) {
			setPollOptionVote(data.pollOption);

			const generatePollOptionAnim: PollOption = {
				...pollOption,
				uuid: uuidv4(),
				versionAnim: `anim${animationVersionPollVote}`,
			};
			animationVersionPollVote = animationVersionPollVote < 5 ? animationVersionPollVote + 1 : 1;
			addPollVoteAnim(generatePollOptionAnim);
		}
	};

	const onBlockSetHandler = (data: BlockSet) => {
		const {isBlocked, initiatorId, targetId} = data;

		if (initiatorId === userData?.id) {
			roomStore.toggleBlockedUserForFilteredMessages(targetId, isBlocked);
			if (isBlocked) {
				roomStore.removeMentionMessageForBlockedUser(targetId);
				return;
			}
			removeBlockedUser(targetId);
		}

		if (targetId === userData?.id) {
			roomStore.toggleBlockedUserForFilteredMessages(initiatorId, isBlocked);
			if (isBlocked) {
				roomStore.removeMentionMessageForBlockedUser(initiatorId);
			}
		}
	};

	const onRemoveMessageHandler = (data: {messageId: number}) => {
		if (userData) {
			roomStore.removeMessage(data.messageId, userData, () => {
				addToast({
					iconName: ToastIconsName.basket,
					liveTime: 3000,
					message: translations.toasts.messageRemoved,
					cancellable: true,
				});
			});

			threadStore.removeMessage(data.messageId, userData, () => {
				addToast({
					iconName: ToastIconsName.basket,
					liveTime: 3000,
					message: translations.toasts.messageRemoved,
					cancellable: true,
				});
			});

			if (roomPreviousMessagesModeRef.current) {
				roomStore.removeUnrecordedMessage(data.messageId);
			}

			if (threadPreviousMessagesModeRef.current) {
				threadStore.removeUnrecordedMessage(data.messageId);
			}
		}
	};

	const onReactionCreatedHandler = (data: {reaction: Reaction; room?: Room}) => {
		threadStore.addReactionToMessage(data.reaction);
		roomStore.addReactionToMessage(data.reaction);
	};

	const onReactionDeletedHandler = (data: {reaction: Reaction; room?: Room}) => {
		threadStore.removeReactionToMessage(data.reaction);
		roomStore.removeReactionToMessage(data.reaction);
	};

	const onRoomSlowmodeSetHandler = (data: {room: Room}) => {
		const {isSlowmode, slowmodeDelayMS} = data.room;
		roomStore.updateRoomData({isSlowmode, slowmodeDelayMS});
		togglleSlowMode({
			local: {
				enable: isSlowmode,
				time: slowmodeDelayMS || 1000,
			},
		});
		if (!isSlowmode) {
			apikeyFromUrl && getSettingsPartnerWithServices(apikeyFromUrl);
		}
	};

	const onRoomSlowmodeDelaySetHandler = (data: {room: Room}) => {
		const {slowmodeDelayMS} = data.room;
		roomStore.updateRoomData({slowmodeDelayMS});
		togglleSlowMode({
			local: {
				enable: !!slowModeRef.current?.local.enable,
				time: slowmodeDelayMS || 1000,
			},
		});
	};

	const onSettingsUpdatedHandler = (data: {settings: Settings}) => {
		const {enableSlowmode, slowmodeDelayMS} = data.settings;
		if (typeof enableSlowmode !== 'undefined' && typeof slowmodeDelayMS !== 'undefined') {
			togglleSlowMode({
				global: {
					enable: enableSlowmode,
					time: slowmodeDelayMS || 1000,
				},
			});
		}
	};

	const onBetEditedHandler = (data: {bet: Bet}) => {
		let messages: Message[] = [];
		if (data.bet.messages) messages = [...data.bet.messages];
		if (Array.isArray(messages))
			messages.map((el: Message) => roomStore.updateMessageFieldById(el.id, {bet: el.bet}));
	};

	const onMessageUnpinnedHandler = (data: {message: Message}) => {
		roomStore.removePinnedMessage(data.message);
	};

	return {
		onUserJoinedHandler,
		onSuccessfullyJoined,
		onSuccessfullyJoinedThread,
		onGetOffersHandler,
		onUserLeftHandler,
		onGetMessageHandler,
		onMessageNotSentHandler,
		onMessageEditedHandler,
		onMessagesDeletedHandler,
		onMessagesVisibleHandler,
		onMessageVisibleHandler,
		talkerBanSetHandler,
		onUserBanSetHandler,
		reciveChangeRole,
		onChangeModerHandler,
		onHandToggledHandler,
		reciveAgoraCredsHandler,
		onEmotionHandler,
		onRoomSpeakSetHandler,
		onRoomStatusSet,
		onMuteSetHandler,
		onUserUpdatedHandler,
		onMessagePin,
		onPollCreatedHandler,
		onPollDeletedHandler,
		onPollUpdatedHandler,
		onPollEndedHandler,
		onVoteCreatedHandler,
		onBlockSetHandler,
		onRemoveMessageHandler,
		onReactionCreatedHandler,
		onReactionDeletedHandler,
		onRoomSlowmodeSetHandler,
		onRoomSlowmodeDelaySetHandler,
		onSettingsUpdatedHandler,
		showUserNameInChat,
		onBetEditedHandler,
		onMessageUnpinnedHandler,
	};
};
